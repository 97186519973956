<template>
  <b-card
    class="overflow-hidden"
    no-body
  >
    <b-table
      responsive
      :items="invoices"
      :fields="fields"
    >
      <template #cell(folio)="data">
        <quote-button-route :quote="data.item.quote" />
      </template>
      <template #cell(supplier)="data">
        <div class="text-primary font-weight-bolder">
          {{ data.item.supplier_branch.name }}
        </div>
      </template>
      <template #cell(content_type)="data">
        <div class="text-primary font-weight-bolder">
          {{ data.value }}
        </div>
      </template>
      <template #cell(total)="data">
        $ {{ data.item.quote.total | money }}
      </template>
      <template
        v-if="!noTools"
        #cell(tools)="data"
      >
        <div>
          <b-button
            variant="primary"
            class="p-0 custom-padding mr-1"
            @click="downloadPurchaseOrderInovices(data.item)"
          >
            <feather-icon
              icon="DownloadIcon"
              class="text-white"
              size="18"
              color="#000"
            />
          </b-button>
          <b-button
            variant="primary"
            class="p-0 custom-padding"
            :to="{
              name: 'purchaseOrderInvoices',
              params: { quoteId: data.item.id },
            }"
          >
            <feather-icon
              icon="EyeIcon"
              class="text-white"
              size="18"
              color="#000"
            />
          </b-button>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { BTable } from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import QuoteButtonRoute from '@/components/pricing/QuoteButtonRoute.vue'

export default {
  components: {
    BTable,
    QuoteButtonRoute,
  },
  props: {
    invoices: {
      type: Array,
      default() {
        return []
      },
    },
    noTools: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'folio',
          label: 'Folio',
        },
        {
          key: 'supplier',
          label: this.$t('supplier'),
        },
        // {
        //   key: 'date',
        //   label: this.$t('date'),
        // },
        {
          key: 'invoiced_at',
          label: this.$t('invoiceDate'),
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'total',
          label: this.$t('total'),
          tdClass: 'text-left',
          thClass: 'text-left',
        },
        {
          key: 'tools',
          label: this.$t('actions'),
        },
      ],
    }
  },

  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('quotes', ['quote']),
    ...mapGetters('purchaseOrders', ['purchaseOrder']),
  },

  methods: {
    ...mapActions('purchaseOrders', [
      'getPurchaseOrderInovices',
      'deletePurchaseOrderInovice',
      'downloadPurchaseOrderInovices',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.custom-padding {
  padding: 3px !important;
}
</style>
