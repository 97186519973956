<template>
  <div>
    <b-row>
      <b-col class="d-flex justify-content-between pb-1 align-items-center">
        <h1 class="font-weight-bolder">
          {{ $t('purchaseOrders') }}
        </h1>
      </b-col>
    </b-row>
    <b-overlay :show="loading">
      <b-row>
        <b-col>
          <purchase-orders-table :invoices="purchaseOrders" />
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PurchaseOrdersTable from '@/components/invoice/SupplierInvoicesTable.vue'

export default {
  components: {
    PurchaseOrdersTable,
  },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('purchaseOrders', ['purchaseOrders']),
    ...mapGetters('projects', ['project']),
  },

  created() {
    this.loading = true
    this.fetchPurchaseOrders({
      quoteId: this.$route.params.quoteId,
    }).then(() => {
      this.loading = false
    })
  },

  methods: {
    ...mapActions('purchaseOrders', ['fetchPurchaseOrders']),
  },
}
</script>

<style></style>
